import Image from "next/image";

export function Images(props) {
  return (
    <Image
      src={props.src}
      alt={props.alt}
      width={props.width}
      height={props.height}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    />
  );
}

export default Images;
