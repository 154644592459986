import { useIntl } from "react-intl";
import LogoData from "../variables/variables.json";
import { useRouter } from "next/router";
import TranslatedMessage from "./TranslatedMessage";
import Image from "next/image";
import classNames from "classname";
import Link from "next/link";

const Logo = (props) => {
  const router = useRouter();
  const intl = useIntl();

  // PROPS VARIABLE
  let propsMiasto = props.miasto;
  let propsPodatek = props.podatek;
  let miastoVariable = "miasta." + propsMiasto;

  let propsType;
  let extendedUrl = "";
  let siteCode = router.query.siteCode;

  if (LogoData.miasta[propsMiasto].index) {
    propsType = "/";
  } else if (LogoData.miasta[propsMiasto].witryna) {
    propsType = "/witryna/";
    extendedUrl = "?siteCode=" + siteCode;
  } else {
    propsType = "/";
  }

  return (
    <div
      className={classNames("main-logo", {
        "main-logo--with-text": LogoData.miasta[propsMiasto].logo.withText,
      })}
    >
      <Link className="main-logo__link" href={propsType + extendedUrl}>
        <div className="main-logo__img">
          <div className="main-logo__img__wrapper main-logo__img--desktop">
            <Image
              src={LogoData.miasta[propsMiasto].logo.images.desktop.url}
              alt={intl.formatMessage({
                id: miastoVariable + ".logo.images.desktop.alt",
              })}
              width={LogoData.miasta[propsMiasto].logo.images.desktop.width}
              height={LogoData.miasta[propsMiasto].logo.images.desktop.height}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <div className="main-logo__img__wrapper main-logo__img--mobile">
            <Image
              className="main-logo__img--mobile"
              src={LogoData.miasta[propsMiasto].logo.images.mobile.url}
              alt={intl.formatMessage({
                id: miastoVariable + ".logo.images.mobile.alt",
              })}
              width={LogoData.miasta[propsMiasto].logo.images.mobile.width}
              height={LogoData.miasta[propsMiasto].logo.images.mobile.height}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <div className="main-logo__img__wrapper main-logo__img--highcontrast">
            <Image
              className="main-logo__img--highcontrast"
              src={LogoData.miasta[propsMiasto].logo.images.contrast.url}
              alt={intl.formatMessage({
                id: miastoVariable + ".logo.images.contrast.alt",
              })}
              width={LogoData.miasta[propsMiasto].logo.images.contrast.width}
              height={LogoData.miasta[propsMiasto].logo.images.contrast.height}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
        <div className="main-logo__text">
          <span>
            <TranslatedMessage id={miastoVariable + ".logo.name.type"} />
          </span>
          <span>
            <TranslatedMessage id={miastoVariable + ".logo.name.title"} />
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Logo;
